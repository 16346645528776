import { Get, getAPIUrl, Post } from "../../apiMethod";
import { CourseService } from "../../Axios/Index";
import { Coursendpoint } from "./CourseEndpoint";

export const GetCourses = (param = "") => {
  const url = getAPIUrl(Coursendpoint.list, param);
  return Get(CourseService, url);
};
export const GetCourseDetail = (param = "") => {
  const url = getAPIUrl(Coursendpoint.detail, param);
  return Get(CourseService, url);
};

export const GetReviews = (param = "") => {
  const url = getAPIUrl(Coursendpoint.reviews, param);
  return Get(CourseService, url);
};
export const WriteReview = (data, param = "") => {
  const url = getAPIUrl(Coursendpoint.reviews, param);
  return Post(CourseService, url, data);
};
export const UpdateCourseProgress = (data, param = "") => {
  const url = getAPIUrl(Coursendpoint.updateProgress, param);
  return Post(CourseService, url, data);
};
export const getCategories = (param = "") => {
  const url = getAPIUrl(Coursendpoint.categories, param);
  return Get(CourseService, url);
};
export const getRatingCount = (param = "") => {
  const url = getAPIUrl(Coursendpoint.ratingCount, param);
  return Get(CourseService, url);
};

export const getMyCourses = (param = "") => {
  const url = getAPIUrl(Coursendpoint.mycourses, param);
  return Get(CourseService, url);
};
export const GetPeers = (param = "") => {
  const url = getAPIUrl(Coursendpoint.getpeers, param);
  return Get(CourseService, url);
};

export const getUserStats = (param = "") => {
  const url = getAPIUrl(Coursendpoint.userStats, param);
  return Get(CourseService, url);
};
export const presignedApi = (param = "") => {
  const url = getAPIUrl(Coursendpoint.presignedApi, param);
  return Get(CourseService, url);
};
export const searchApi = (param = "") => {
  const url = getAPIUrl(Coursendpoint.search, param);
  return Get(CourseService, url);
};
