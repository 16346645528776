import { Drawer, Progress, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../ImageController/Index";
import { FaArrowRightLong } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { buildSearchQuery, calculateFilledPercentage, getGreeting, trimString } from "../Utils";
import { UPLOAD_ENDPOINT } from "../ApiServices/Axios/Index";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { orderList } from "../ApiServices/Apis/Order/OrderFun";
import { getAnnouncement } from "../ApiServices/Apis/Notification/Notificationfun";
import NotificationModal from "../CommonComponents/NotificationModal";

const ProfileSidebar = ({ open, setOpen }) => {
  const userDetail = useSelector((state) => state.E2C.userDetail);
  const SanitizedDetail = {
    socialUrl: userDetail.socialUrl,
    interest: userDetail.interest,
    firstName: userDetail.firstName,
    lastName: userDetail.lastName,
    address: userDetail.address,
    headline: userDetail.headline,
    profilePicture: userDetail.profilePicture,
  };
  const [transactions, setTransactions] = useState([]);
  const [hasMore, setHasMore] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const navigate = useNavigate();
  const getOrderList = () => {
    orderList()
      .then((res) => {
        if (res.success) {
          setTransactions(res?.data?.items);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleGetAnnouncement = () => {
    const param={
      limit:10,
      nextCursor:hasMore
    }
    getAnnouncement(buildSearchQuery(param))
      .then((res) => {
        if (res.status === "1") {
          setAnnouncements([...announcements, ...res.result.data]);
          setHasMore(res.result.nextCursor);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getOrderList();
    handleGetAnnouncement();
    //eslint-disable-next-line
  }, []);
  return (
    <Drawer
      closeIcon={null}
      width={370}
      open={open}
      onClose={() => setOpen(false)}
    >
      <div className="flex justify-between items-center font-poppins">
        <p className="font-dmsans text-lg font-semibold">Your Profile</p>
        <IoClose
          onClick={() => setOpen(false)}
          className="font-semibold"
          size={18}
        />
      </div>
      <div className="flex flex-col font-dmsans justify-center items-center mt-16">
        <div
          onClick={() => {
            navigate("/profile");
            setOpen(false);
          }}
          className="relative cursor-pointer"
        >
          <Progress
            strokeColor="#03CD23"
            type="circle"
            showInfo={false}
            percent={calculateFilledPercentage(SanitizedDetail)}
          />
          <LazyLoadImage
            onError={(e) => {
              e.target.src = Images.DummyImage;
              e.target.alt = "dummyImage";
            }}
            src={UPLOAD_ENDPOINT + userDetail.profilePicture}
            className="w-24 h-24 bottom-2 right-3 absolute top-3 rounded-full object-contain"
          />
        </div>
        <p className="font-bold text-lg capitalize">
          {getGreeting()} {userDetail?.firstName}
        </p>
        <p className="text-gray2 font-semibold mt-2 text-center">
          continue your journey and achieve Your Target
        </p>
      </div>
      <div className="font-dmsans border border-[#E0E9FF] rounded-lg p-3 mt-4">
        <p className="font-bold text-lg text-[#1B2559]">Announcements</p>
        {announcements.length <= 0 ? (
          <div className="flex justify-center items-center font-poppins font-bold mt-10">
            <p>No Announcements</p>
          </div>
        ) : (
          <div className="max-h-[200px] overflow-scroll">
            {announcements.map((i, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-start items-center gap-x-4 mt-4"
                >
                  <div className="border border-orange h-[44px] rounded-full w-[4px] bg-orange"></div>
                  <div
                    onClick={() => {
                      setopenModal(true);
                      setSelectedData(i);
                    }}
                    className="flex w-full justify-between cursor-pointer items-center"
                  >
                    <div>
                      <p className="font-bold">{i?.Type}</p>
                      <Tooltip
                        title={
                          <p className="text-black font-inter text-xs">
                            {i.body}
                          </p>
                        }
                        color="white"
                        arrow={false}
                      >
                        <p className="text-[#A3AED0] mt-1 font-semibold">
                          {trimString(i.body, 20)}
                        </p>
                      </Tooltip>
                    </div>
                    <p className="text-xs font-inter text-gray-500">
                      {new Date(i.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {hasMore && (
          <p
            onClick={handleGetAnnouncement}
            className="text-center text-orange font-bold flex justify-center items-center gap-x-2 mt-4 cursor-pointer"
          >
            Show More <FaArrowRightLong />
          </p>
        )}
      </div>
      <div className="font-dmsans border border-[#E0E9FF] rounded-lg p-3 mt-4">
        <p className="font-bold text-lg text-[#1B2559]">
          Your Purchase/ Transactions
        </p>
        {transactions?.length > 0 ? (
          transactions.slice(0, 3)?.map((i, index) => {
            return (
              <div className="flex justify-between items-center mt-4">
                <div className="w-10 h-10 rounded-full  bg-[#F4F7FE]"></div>
                <div className=" w-[50%]">
                  <p className="font-bold">
                    {trimString(i?.merchantTransactionId, 15)}
                  </p>
                  <p className="text-[#A3AED0] mt-1 font-semibold">
                    {new Date(i.createdAt).toDateString()}
                  </p>
                </div>
                <p className="font-semibold w-[25%]">-₹{i?.amount}</p>
              </div>
            );
          })
        ) : (
          <div className="flex justify-center items-center mt-6 font-dmsans font-bold">
            <p>No Transactions Yet</p>
          </div>
        )}

        {transactions?.length > 3 && (
          <p
            onClick={() => {
              navigate("/orders");
              setOpen(false);
            }}
            className="text-center text-orange font-bold flex justify-center items-center gap-x-2 mt-4 cursor-pointer"
          >
            View All <FaArrowRightLong />
          </p>
        )}
      </div>
      {openModal && (
        <NotificationModal
          open={openModal}
          setOpen={setopenModal}
          data={selectedData}
        />
      )}
    </Drawer>
  );
};

export default ProfileSidebar;
