export const Coursendpoint = {
  list: "/list",
  detail: "/details",
  reviews: "/reviews",
  writeReview:"/review",
  updateProgress: "/updateProgress",
  categories: "/categories",
  ratingCount: "/ratingCount",
  mycourses: "/myCourses",
  getpeers: "/getPeers",
  userStats: "/userStats",
  presignedApi: "/getProfileUploadPresignedUrl",
  search: "/search",
};
