import { createSlice } from "@reduxjs/toolkit";
// import { getLocalStorageItem } from "../Utils";

const initialState = {
  userDetail:null,
  selectedTab:"1"
};

export const b2bSlice = createSlice({
  name: "E2C",
  initialState,
  reducers: {
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserDetail,setSelectedTab } = b2bSlice.actions;

export default b2bSlice.reducer;
