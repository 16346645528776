import { BrowserRouter, useRoutes } from "react-router-dom";
import "./App.css";
import "react-slideshow-image/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PrivateAuth } from "./Routes/PrivateRoutes";
import { PublicRoutes } from "./Routes/PublicRoutes";
import "react-toastify/dist/ReactToastify.css";
import { decodeAccessToken, ToastContainerMsg } from "./Utils";

function App() {
  console.log(
    decodeAccessToken(
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2Njk4ZDcyYWU5YTc1MzE4MDExZmE0NjMiLCJyb2xlIjoic3R1ZGVudCIsImVtYWlsIjoiZGVlcGFrQGV6dWxpeC5jb20iLCJpYXQiOjE3MjU4Nzk2MzZ9.4w4cKLQoQICjyEGguKpOdwPZv6CKvFst3srM6iB73s4"
    )
  );
  const Routing = [...PublicRoutes(), ...PrivateAuth()];
  const RouteConfig = () => useRoutes(Routing);

  return (
    <>
      <BrowserRouter>
        <RouteConfig />
      </BrowserRouter>
      <ToastContainerMsg />
    </>
  );
}

export default App;
