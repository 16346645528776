import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { getNotification, MarkallReadNotification, ReadNotification } from "../ApiServices/Apis/Notification/Notificationfun";
import { buildSearchQuery } from "../Utils";
import { UPLOAD_ENDPOINT } from "../ApiServices/Axios/Index";

const NotificationsDrawer = ({ open, setOpen }) => {
  const [notificationList, setNotificationList] = useState([]);

  const [hasMore, setHasMore] = useState(null);
  const handleGetAllnotification = () => {
    const param = {
      limit: 10,
      nextCursor: hasMore,
    };
    getNotification(buildSearchQuery(param))
      .then((res) => {
        if (res.status === "1") {
          setNotificationList([...notificationList, ...res.result.data]);
          setHasMore(res.result.nextCursor);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleMarkAsAllRed = () => {
    MarkallReadNotification()
      .then((res) => {
        if (res.status == "1") {
          let data = [...notificationList];
          setNotificationList(
            data.map((i) => {
              return { ...i, is_read: true };
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const handleReadNotification = (data, index) => {
    ReadNotification(`/${data._id}`)
      .then((res) => {
        if (res.status === "1") {
          let data = [...notificationList];
          data[index] = { ...data[index], is_read: true };
          setNotificationList(data);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    handleGetAllnotification();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <Drawer onClose={() => setOpen(false)} closeIcon={<IoMdClose color="white" />} open={open} title={<p className="font-poppins text-white ">Notifications</p>} headerStyle={{ background: "var(--primary)" }}>
        {notificationList?.length > 0 && (
          <div className="flex justify-end font-inter items-center cursor-pointer text-gray-500 text-xs font-bold mb-5" onClick={handleMarkAsAllRed}>
            Mark All As Read
          </div>
        )}
        {notificationList?.length > 0 ? (
          <div className="max-h-[85%] overflow-scroll">
            {notificationList.map((i, index) => (
              <NotificationTile i={i} index={index} handleReadNotification={handleReadNotification} />
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center text-sm font-poppins mt-14 font-bold">
            <p>No Notification found</p>
          </div>
        )}
        {hasMore && (
          <div onClick={handleGetAllnotification} className=" flex justify-center items-center">
            <button className="bg-orange px-3 py-1 rounded-none text-white mt-8">Load More</button>
          </div>
        )}
      </Drawer>
    </>
  );
};

const NotificationTile = ({ i, index, handleReadNotification }) => (
  <div className={`cursor-pointer mx-2 mb-5 ${i?.is_read ? "shadow-sm" : "shadow-md"} shadow-gray-300 overflow-hidden rounded-md`}>
    <a
      key={index}
      href={i?.link}
      onClick={() => {
        if (!i?.is_read) {
          handleReadNotification(i, index);
        }
      }}
      target="blank"
    >
      <div className="flex flex-row">
        <img src={UPLOAD_ENDPOINT + i?.image} alt="notificationimage" className="w-[30%] object-cover" />
        <div className="p-3">
          <p className="text-sm font-bold text-gray-800">{i?.title}</p>
          <p className="text-xs font-normal text-gray-600">{i?.body}</p>
          <p className="text-xs font-semibold text-gray-800">{new Date(i?.createdAt).toDateString()}</p>
        </div>
      </div>
    </a>
  </div>
);

export default NotificationsDrawer;
