import React, { Suspense, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../ImageController/Index";
import { Button, Form, Input, Spin } from "antd";
import {
  CommonAnalyticAdd,
  decodeAccessToken,
  emailPattern,
  fetchUserProfile,
  getLocalStorageItem,
  handleBuyCourse,
  makeLabel,
  setLocalStorageItem,
} from "../../Utils";
import CommonInput from "../../CommonComponents/CommonInput";
import { FaArrowRight } from "react-icons/fa";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  FacebookAuth,
  GoogleAuth,
  userLogin,
} from "../../ApiServices/Apis/Auth/AuthFun";

const Login = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();
  const handleLogin = async (val) => {
    setBtnLoading(true);
    await userLogin(val)
      .then((res) => {
        if (res.status) {
          fetchUserProfile(res.token)
            .then((resp) => {
              if (resp.data.status) {
                const decodeToken = decodeAccessToken(res.token);
                CommonAnalyticAdd({
                  user_id: decodeToken.userId,
                  user_name: `${resp.data.data.firstName} ${resp.data.data.lastName}`,
                  channel: "email",
                  event_name: decodeToken.eventName,
                });
              }
            })
            .catch((err) => console.log(err));
          setLocalStorageItem("accessToken", res.token);
          setLocalStorageItem("IsLoggesIn", true);
          if (
            getLocalStorageItem("courseId") &&
            getLocalStorageItem("payinInstallment")
          ) {
            const payload = {
              courseId: getLocalStorageItem("courseId"),
              payInInstallments: getLocalStorageItem("payinInstallment"),
            };
            handleBuyCourse(res.token, payload)
              .then((res) => window.open(res.data.redirectInfo, "_blank"))
              .catch((err) => console.log(err));
          } else {
            navigate("/dashboard");
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const handleGoogleAuth = () => {
    GoogleAuth()
      .then((res) => {
        window.open(res.url, "_self");
      })
      .catch((err) => console.log(err));
  };
  const handleFacebookAuth = () => {
    FacebookAuth()
      .then((res) => {
        window.open(res.url, "_self");
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setLocalStorageItem("courseId", searchParam.get("courseId"));
    setLocalStorageItem(
      "payinInstallment",
      searchParam.get("payinInstallment")
    );
  }, []);
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-screen">
          <Spin spinning={true}></Spin>
        </div>
      }
    >
      <div className="container flex justify-center items-center h-screen">
        <div className="grid grid-cols-2 place-items-center ">
          <div className="w-full bg-authBg h-screen hidden md:flex justify-center items-center ">
            <LazyLoadImage
              src={Images.Login}
              alt="signupimage"
              className="w-[70%]"
            />
          </div>
          <div className="w-full h-screen flex justify-center items-center col-span-2 md:col-span-1">
            <div className="w-3/4 m-auto">
              <div className="font-poppins text-center text-xl font-bold">
                <p>Sign in to your account</p>
              </div>
              <Form onFinish={(val) => handleLogin(val)}>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "Email Is Required",
                    },
                    {
                      pattern: emailPattern,
                      message: "Please Enter Valid Email",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel("Email")}
                >
                  <CommonInput placeHolder="Username or email address..." />
                </Form.Item>
                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Password Is Required",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel("Password")}
                >
                  <Input.Password
                    className="rounded-none  hover:border hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                    placeholder="Password"
                  />
                </Form.Item>

                <div className="flex justify-end items-center">
                  <Button
                    loading={btnLoading}
                    type="primary"
                    iconPosition="end"
                    icon={<FaArrowRight />}
                    htmlType="submit"
                    style={{ color: "white" }}
                    prefix="jj"
                    className="font-poppins text-white font-bold rounded-none bg-primary"
                  >
                    Sign In
                  </Button>
                </div>
              </Form>
              <NavLink to={"/signup"}>
                <p className="text-gray1 mt-3 cursor-pointer text-sm font-poppins text-center">
                  Don't have an account? Sign Up
                </p>
              </NavLink>
              <p className="text-[#8C94A3] mt-3 text-xs font-poppins text-center">
                SIGN UP WITH
              </p>
              <div className="flex justify-center items-center mt-2 gap-x-4">
                <LazyLoadImage
                  onClick={handleGoogleAuth}
                  src={Images.Google}
                  alt="google"
                  className="w-[35%]"
                />
                <LazyLoadImage
                  onClick={handleFacebookAuth}
                  src={Images.facebook}
                  alt="facebook"
                  className="w-[35%]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default Login;
