import { lazy } from "react";
import ProtectedAuth from "./PrivateAuth";
const Dashboard = lazy(() => import("../Views/Main/Dashboard/Dashboard"));
const Courses = lazy(() => import("../Views/Main/Course/Courses"));
const Cart = lazy(() => import("../Views/Main/Cart/Cart"));
const Checkout = lazy(() => import("../Views/Main/Checkout/Checkout"));
const OrderSuccess = lazy(() =>
  import("../Views/Main/OrderSuccess/OrderSuccess")
);
const CourseDetail = lazy(() => import("../Views/Main/Course/CourseDetail"));
const Discussion = lazy(() => import("../Views/Main/Discussion/Discussion"));
const Profile = lazy(() => import("../Views/Main/Profile/Profile"));
const Mycourses = lazy(() => import("../Views/Main/MyCourses/Mycourses"));
const CourseOverview = lazy(() =>
  import("../Views/Main/CourseOverview/CourseOverview")
);
const PurchasedCourse = lazy(() =>
  import("../Views/Main/Course/PurchasedCourse")
);
export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/Dashboard",
          element: <Dashboard />,
        },
        {
          path: "/Courses",
          element: <Courses />,
        },
        {
          path: "/courseDetail/:courseId",
          element: <CourseDetail />,
        },
        {
          path: "/Cart",
          element: <Cart />,
        },
        {
          path: "/Checkout",
          element: <Checkout />,
        },
        {
          path: "/Order-status/:verifyId?/:courseId",
          element: <OrderSuccess />,
        },
        {
          path: "/my-courses",
          element: <Mycourses />,
        },
        {
          path: "/orders",
          element: <PurchasedCourse />,
        },
        {
          path: "/courseOverview/:courseId?",
          element: <CourseOverview />,
        },
        {
          path: "/discussion",
          element: <Discussion />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        // ERROR ROUTES ENDED
      ],
    },
  ];
};
