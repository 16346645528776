import { Modal } from "antd";
import React from "react";

const NotificationModal = ({ open, setOpen, data }) => {
  return (
    <>
      <Modal
        footer={null}
        open={open}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
      >
        <div className="font-inter">
          {data?.Type && <p className="font-bold text-lg">{data?.Type}</p>}
          <p className="mt-2 text-sm font-semibold text-gray-400">
            {data?.body}
          </p>
        </div>
      </Modal>
    </>
  );
};

export default NotificationModal;
