import { Get, getAPIUrl } from "./apiMethod";
import { UserService } from "./Axios/Index";
import { commonendpoint } from "./CommonEndpoint";

export const getUserProfile = (param = "") => {
  const url = getAPIUrl(commonendpoint.userprofile, param);
  return Get(UserService, url);
};


