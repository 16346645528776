import { CiCalendar, CiSettings } from "react-icons/ci";
import { FaAddressBook } from "react-icons/fa";
import { GoCommentDiscussion } from "react-icons/go";
import { MdDashboard, MdOutlineAccountBox } from "react-icons/md";
import { TiBookmark } from "react-icons/ti";
import { NavLink } from "react-router-dom";

export const MenuItems = () => {
  const list = [
    {
      key: "1",
      icon: <MdDashboard />,
      title: null,
      label: (
        <NavLink to={"/dashboard"}>
          <p>Dashboard</p>
        </NavLink>
      ),
    },
    {
      key: "2",
      icon: <FaAddressBook />,
      title: null,
      label: (
        <NavLink to={"/Courses"}>
          <p>Courses</p>
        </NavLink>
      ),
    },
    {
      key: "3",
      icon: <TiBookmark />,
      title: null,
      label: (
        <NavLink to={"/my-courses"}>
          <p>My Coureses</p>
        </NavLink>
      ),
    },
    {
      key: "4",
      icon: <GoCommentDiscussion />,
      title: null,
      label: (
        <NavLink to={"/discussion"}>
          <p>Discussion</p>
        </NavLink>
      ),
    },
    // {
    //   key: "5",
    //   icon: <CiCalendar />,
    //   title: null,
    //   label: <p>Schedule</p>,
    // },
    {
      key: "6",
      icon: <MdOutlineAccountBox />,
      title: null,
      label: (
        <NavLink to={"/profile"}>
          <p>My Account</p>
        </NavLink>
      ),
    },
    // {
    //   key: "7",
    //   icon: <CiSettings />,
    //   title: null,
    //   label: <p>Setting</p>,
    // },
  ];
  return list;
};
