import { Get, getAPIUrl, Post } from "../../apiMethod";
import { OrderService } from "../../Axios/Index";
import { Orderendpoint } from "./OrderEndpoint";

export const BuyNow = (data, param = "") => {
  const url = getAPIUrl(Orderendpoint.buyNow, param);
  return Post(OrderService, url, data);
};
export const getCourseInstallments = (param = "") => {
  const url = getAPIUrl(Orderendpoint.getInstallments, param);
  return Get(OrderService, url);
};

export const checkOrderStatus = (param = "") => {
  const url = getAPIUrl(Orderendpoint.verify, param);
  return Get(OrderService, url);
};

export const payInstallment = (data, param = "") => {
  const url = getAPIUrl(Orderendpoint.payInstallment, param);
  return Post(OrderService, url, data);
};

export const orderList = (param = "") => {
  const url = getAPIUrl(Orderendpoint.orderList, param);
  return Get(OrderService, url);
};

export const verifyCoupon = (param = "") => {
  const url = getAPIUrl(Orderendpoint.verifyCoupon, param);
  return Get(OrderService, url);
};
