import { Get, getAPIUrl, Put } from "../../apiMethod";
import { NotificationService } from "../../Axios/Index";
import { NotificationEndpoint } from "./NotificationEndpoint";

export const getNotification = (param = "") => {
  const url = getAPIUrl(NotificationEndpoint.getNotification, param);
  return Get(NotificationService, url);
};

export const ReadNotification = (param = "") => {
  const url = getAPIUrl(NotificationEndpoint.readNotification, param);
  return Put(NotificationService, url);
};
export const MarkallReadNotification = (param = "") => {
  const url = getAPIUrl(NotificationEndpoint.markAllRedNotification, param);
  return Put(NotificationService, url);
};
export const getAnnouncement = (param = "") => {
  const url = getAPIUrl(NotificationEndpoint.getAnnouncement, param);
  return Get(NotificationService, url);
};
