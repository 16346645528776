import { IoMdClose } from "react-icons/io";
import { UPLOAD_ENDPOINT } from "../ApiServices/Axios/Index";

export const ToastNotification = ({ i, closeToast, toastProps }) => (
  <div className={`cursor-pointer mx-2 mb-5 overflow-hidden rounded-md`}>
    <div onClick={closeToast} className="cursor-pointer absolute right-5 top-5">
      <IoMdClose />
    </div>
    <a href={i?.link} target="blank">
      <div className="flex flex-row">
        <img src={UPLOAD_ENDPOINT + i?.image} alt="notificationimage" className="w-[30%] object-cover" />
        <div className="p-3">
          <p className="text-sm font-bold text-gray-800">{i?.title}</p>
          <p className="text-xs font-normal text-gray-600">{i?.body}</p>
          <p className="text-xs font-semibold text-gray-800">{new Date(i?.createdAt).toDateString()}</p>
        </div>
      </div>
    </a>
  </div>
);
